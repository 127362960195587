import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data01/virt128836/domeenid/www.kursused.ebs.ee/test.kursused/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data01/virt128836/domeenid/www.kursused.ebs.ee/test.kursused/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/data01/virt128836/domeenid/www.kursused.ebs.ee/test.kursused/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data01/virt128836/domeenid/www.kursused.ebs.ee/test.kursused/src/app/_components/SeasonPicker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data01/virt128836/domeenid/www.kursused.ebs.ee/test.kursused/src/app/[locale]/courses/english-courses-notification.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data01/virt128836/domeenid/www.kursused.ebs.ee/test.kursused/src/app/[locale]/courses/no-courses-notification.tsx");
